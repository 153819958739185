<template>
  <div>
    <br>
    <br>

    <div class="all-page-loader"
      v-if="isLoading">
      <div class="page-loader"></div>
    </div>

    

    <div class="container" style="background-color: #ffffff;padding: 20px;border-radius: 13px;"
      v-if="notifi.show == false || notifi.isClose">
      
      <div>
        <router-link :to="'/smed/'+$route.params.idEscola">Voltar para a lista</router-link>
      </div>
      <div>
        <span class="title is-4">
          Computador da: 
        </span>
        <span class="title is-3">
          {{this.escola.nome}}
        </span>
      </div>
      <br>
      <div v-if="false">
        

        <div class="columns" v-if="equipamento.isOperante >= 2">
          <div class="column">
            <div class="control">
              <label class="label">
                Você precisa da ajuda de um tecnico pra infoarmar o problema?
                Ou você esta apto a informar? (Obrigátorio)
              </label>
              <label class="radio">
                <input type="radio" id="radio2" value="1" v-model="equipamento.isApto">
                Preciso de ajuda
              </label>
              <br>
              <label class="radio">
                <input type="radio" id="radio2" value="2" v-model="equipamento.isApto">
                Estou apto a informar. Pois já foi verificado ou tenho conhecimento.
              </label>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <div v-if="equipamento.isOperante >= 2">
              <div class="field">
                <label class="label">Informe o que puder do problema</label>
                <div class="control">
                  <textarea class="textarea" placeholder="Probelma ..." v-model="equipamento.problema">
                  </textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>

      <div>
        


        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">Onde este equipamento esta localizado? (Obrigátorio)</label>
              <div class="control">
                <textarea class="textarea" placeholder="secretaria, almoxarifado ..." rows="1" 
                  v-model="equipamento.localizacao">
                </textarea>
              </div>
            </div>
          </div>
        </div>
        <div>
          <span class="label"
            style="border-bottom: 1px solid #c1c1b7;padding-bottom: 7px;">
            - Equipamentos relacionados
          </span>
        </div>

        <br>

        <div>
          <article class="panel is-primary">
            <a class="panel-block">
              marksheet
            </a>
          </article>
        </div>
        
        <br>
        <div class="is-flex is-justify-content-center">
          <button class="button is-link"
            @click="isModal = true">
            Adicionar
          </button>
        </div>
        

      </div>

      <div
        v-if="notifi.show">
        <!-- <div>
          <router-link :to="'/smed/'+$route.params.idEscola">Voltar para a lista</router-link>
        </div> -->
        <div class="notification is-danger">
          <button class="delete" v-if="notifi.isClose" @click="clickCloseNotifi"></button>
          {{notifi.msg}}
        </div>
      </div>

      <hr>

      <button class="button is-link"
        @click="clickBtnEnviar">
        Enviar
      </button>
      

    </div>


    <div class="modal"
      :class="{'is-active': this.isModal}">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Adicionar equipamento</p>
          <button class="delete" aria-label="close"
            @click="isModal = false">
          </button>
        </header>
        <section class="modal-card-body">

          <div class="columns">
            <div class="column">
              <div class="field">
                <span class="label">Patrimônio (Obrigátorio)</span>
                <div class="control">
                  <input class="input" type="text" placeholder="Patrimônio" v-model="equipamento.patrimonio">
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Tipo (Obrigátorio)</label>
                <div class="control">
                  <div class="select" style="width: 100%;">
                    <select style="width: 100%;" v-model="equipamento.idTipo">
                      <option value="0">Selecione ...</option>
                      <option value="1">Gabinete</option>
                      <option value="2">Monitor</option>
                      <option value="3">Notebook</option>
                      <option value="4">Impressora</option>
                      <option value="5">Roteador</option>
                      <option value="6">Switch</option>
                      <option value="7">Access Point</option>
                      <option value="8">Nobreaks</option>
                      <option value="9">Estabilizador</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <div class="control">
                <label class="label">Equipamento esta em operação? (Obrigátorio)</label>
                <label class="radio" style="margin-right: 21px;">
                  <input type="radio" id="radio1" value="1" v-model="equipamento.isOperante">
                  SIM
                </label>
                <br>
                <label class="radio" style="margin-right: 21px;">
                  <input type="radio" id="radio1" value="2" v-model="equipamento.isOperante">
                  NÃO
                </label>
                <br>
                <label class="radio">
                  <input type="radio" id="radio1" value="3" v-model="equipamento.isOperante">
                  SIM, mas com problema
                </label>
              </div>
            </div>
          </div>
          

        </section>
        <footer class="modal-card-foot">
          <button class="button is-success">Salvar</button>
          <button class="button"
            @click="isModal = false">
            Cancel
          </button>
        </footer>
      </div>
    </div>

  </div>
</template>

<script>
  import Request from '@/utils/Request.js';

  export default {
    
    data() {
      return {
        isModal: false,
        isLoading: false,
        notifi: {
          isClose: false,
          show: false,
          type: 'danger',
          msg: ''
        },
        isAdd: true,
        escola: {},
        equipamento: {
          idTipo: 0,
          isOperante: 1,
          isApto: '0'
        }
      }
    },
    
    mounted() {
      let idEscola = this.getUrlIdEscola();
      this.sendGetEscolaByID(idEscola);
    },

    methods: {
      clickBtnEnviar(){
        if(this.isAdd){
          this.sendPost();
        }
        else{
          this.sendPut();
        }
      },
      getUrlIdEquipamento(){
        return this.$route.params.idEquipamento;
      },
      getUrlIdEscola(){
        return this.$route.params.idEscola;
      },
      sendGetEscolaByID(idEscola){
        this.isLoading = true;
        const success = (response) => {
          this.escola = response.data;
          this.isLoading = false;
          let idEquipamento = this.getUrlIdEquipamento();
          if (idEquipamento != undefined) {
            this.isAdd = false;
            this.sendGetEquipamentoById(idEquipamento);
          }
          else{
            this.isAdd = true;
          }
        }
        const error = (error) => {
          this.isLoading = false;
          this.notifi.show = true;
          this.notifi.isClose = false;
          this.notifi.type = 'danger';
          this.notifi.msg = 'Local não encontrado. Verifique se o endereço da URL esta correto';
        }
        const url = Request.mountUrl2('/escolas/'+idEscola);
        Request.get(url)
          .then(success)
          .catch(error);
      },
      sendGetEquipamentoById(idEquipamento){
        this.isLoading = true;
        const success = (response) => {
          this.equipamento = response.data;
          this.isLoading = false;
        }
        const error = (error) => {
          this.isLoading = false;
          console.log(error);
          this.notifi.show = true;
          this.notifi.isClose = false;
          this.notifi.type = 'danger';
          this.notifi.msg = 'Erro ao carregar equipamento';
        }
        const url = Request.mountUrl2('/equipamentos/'+idEquipamento);
        Request.get(url)
          .then(success)
          .catch(error);
      },
      sendPost(){
        this.isLoading = true;
        let equipamento = Object.assign({}, this.equipamento);
        equipamento.idEscola = this.escola.idEscola;
        if(equipamento.isOperante == 1){
          equipamento.isApto = '0';
        }
        const success = (response) => {
          this.$router.push('/smed/'+this.getUrlIdEscola());
          this.isLoading = false;
        }
        const error = (error) => {
          console.log(error);
          this.isLoading = false;
          this.notifi.show = true;
          this.notifi.isClose = true;
          this.notifi.type = 'danger';
          this.notifi.msg = 'Erro. Verifique se os campos foran preencidos corretamente';
        }
        const url = Request.mountUrl2('/equipamentos');
        Request.post(url, equipamento)
          .then(success)
          .catch(error);
      },
      sendPut(){
        const success = (response) => {
          // this.equipamento = {};
          this.$router.push('/smed/'+this.getUrlIdEscola());
        }
        const error = (error) => {
          console.log(error);
        }
        let idEquipamento = this.equipamento.idEquipamento;
        delete this.equipamento.idEquipamento
        const url = Request.mountUrl2('/equipamentos/'+idEquipamento);
        Request.put(url, this.equipamento)
          .then(success)
          .catch(error);
      },
      clickCloseNotifi(){
        this.notifi.close = false;
        this.notifi.show = false;
      }
    },
    
  }
</script>

<style lang="scss" scoped>

</style>